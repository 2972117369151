<template>
  <div class="industry text-center">
    <font-awesome-icon :icon="faIcon" size="3x" />
    <label class="mt-4">{{ indHeading }}</label>
    <p>{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: "Industry",
  props: {
    faIcon: String,
    indHeading: String,
    text: String,
  },
};
</script>

<style scoped lang="scss">
.industry {
  padding: 1rem;
  border: 1px solid transparent;
  transition: all ease-in 300ms;

  svg {
    color: $theme-color;
  }
  label {
    display: block;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
  p {
    padding: 0.9rem;
    letter-spacing: 0.02rem;
    font-size: 1rem;
    color: $paragraph-inverse;
  }

  &:hover {
    border: 1px solid $theme-color;
  }
}
</style>
