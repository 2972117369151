<template>
  <section class="header">
    <div class="content container">
      <h1 class="w-50">
        <span class="color-theme">Performance</span>
        &
        <span class="color-theme">Quality</span>
        Where You Need It
      </h1>
      <p>
        We are ISO 9001:2015 certified Manufacturers of rubber gaskets and seals
        for DI Pipes, DI Fittings and other water network pipe joints
      </p>

      <br />
      <button class="btn btn-theme me-3" @click="scrollTo()">
        More About Us
      </button>
    </div>
  </section>

  <section id="about-more" ref="about-more">
    <div class="img-background-shape"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <img
            src="../assets/images/about/about-3.jpg"
            alt="About Us"
            class="img-fluid"
          />
        </div>
        <div class="col-md-6">
          <h1>About <strong>Shree Balaji Polytech</strong></h1>
          <div class="underline_highlight"></div>
          <br />
          <br />
          <strong
            >We manufacture rubber gaskets, sealing rings and solutions for
            water pipeline, sewage and drainage infrastructure and water
            infrastructure. We are an ISO 9001:2015 Organisation based out of
            Kolkata, India
          </strong>
          <p class="mt-3">
            Dismantling our rolling mill and starting off in 2003 as a solid
            rubber tyre manufacturer, over the years we have built unique
            competencies in the following products
          </p>
          <ul class="mt-3">
            <li>
              Tyton Gaskets for DI Pipe Tyton Push-On / Socket Joints & DI
              Fittings
            </li>
            <li>Flange Gaskets for DI Pipe Flanged Joints & DI Fittings</li>
            <li>MJ Gaskets for Collar Couplings & Other Fittings</li>
            <li>Valve Encapsulation Solutions</li>
            <li>Manhole Cover Gaskets</li>
            <li>Leak Repair Clamp Gaskets</li>
            <li>Other Customized Rubber Products as per Buyer Enquiries</li>
          </ul>

          <br />
          <h3>Pawan Bagaria</h3>
          <span class="text-highlight">DIRECTOR</span>
        </div>
      </div>

      <div class="mt-5">
        <h1>Mission, Vision & Philosophy</h1>
        <p class="mt-3">
          Our mission is to help build a healthy and sturdy water infrastructure
          in our country and abroad, by providing top-quality rubber products
          (Tyton gaskets, MJ gaskets, Flange gaskets etc.) and solutions
          (Sheets, Seals etc.), with the vision to become one of the largest and
          most trustworthy industrial and infrastructural rubber product brands
          in India
        </p>
      </div>
    </div>
  </section>

  <section id="expertise">
    <div class="container content">
      <h1><strong>Industrial</strong> Solutions</h1>
      <p>
        We believe that a healthy water network is a result of healthy pipes and
        gaskets. Helping build strong value chains at affordable prices is our
        motto. With our rigorous systems in place, we ensure maximum value
        delivered to our clients!
      </p>
      <div class="underline_highlight"></div>
      <br />
      <div class="row mt-5">
        <div class="col-md-4">
          <Industry
            :faIcon="['fa', 'car-alt']"
            :indHeading="'DI Pipes'"
            :text="'Tyton gaskets or Push On gaskets for socket end pipes of all makes'"
          />
        </div>
        <div class="col-md-4">
          <Industry
            :faIcon="['fa', 'subway']"
            :indHeading="'DI Fitttings'"
            :text="'Tyton gaskets or Push On gaskets and flange gaskets for DI fittings such as bends, T joints,  elbows etc. of all makes'"
          />
        </div>
        <div class="col-md-4">
          <Industry
            :faIcon="['fa', 'cogs']"
            :indHeading="'HDPE DWC Pipes'"
            :text="'Rubber rings for DWC Pipes for various underground cable laying and water pipeline purposes'"
          />
        </div>
        <div class="col-md-4">
          <Industry
            :faIcon="['fa', 'industry']"
            :indHeading="'DI Manhole Covers'"
            :text="'Gaskets and profiles for DI Manhole Covers made from various polymers, exported to major countries in Europe'"
          />
        </div>
        <div class="col-md-4">
          <Industry
            :faIcon="['fa', 'atom']"
            :indHeading="'Valve Flanges'"
            :text="'Flange gaskets of 5 mm and 3/6 mm and others for valve ends'"
          />
        </div>

        <div class="col-md-4">
          <Industry
            :faIcon="['fab', 'skyatlas']"
            :indHeading="'Extruded Profiles'"
            :text="'Extruded profiles for various applications including door seals, automobile seals, sewage infrastructure and more'"
          />
        </div>
      </div>
    </div>
  </section>

  <section id="why-choose-us">
    <div class="img-background-shape"></div>
    <div class="container content">
      <div class="row">
        <div class="col-md-6">
          <img src="../assets/images/about/about2.jpg" class="img-fluid" />
        </div>

        <div class="col-md-6">
          <h2>Why Choose Us</h2>
          <p>
            Shree Balaji Polytech has worked for 15 years in Rubber
            Manufacturing
          </p>

          <br />
          <h5>
            <font-awesome-icon :icon="['fab', 'skyatlas']" class="me-3" />
            15 Years Experience in Rubber Industry
          </h5>
          <hr />

          <h5>
            <font-awesome-icon :icon="['fa', 'thumbs-up']" class="me-3" />
            Best Quality Products Used
          </h5>
          <hr />

          <h5>
            <font-awesome-icon :icon="['fa', 'check-circle']" class="me-3" />
            Competent & Experienced Team
          </h5>
          <hr />
          <h5>
            <font-awesome-icon :icon="['fa', 'check-circle']" class="me-3" />
            Industry Approved Technology
          </h5>
          <br /><br />
          <router-link :to="{ name: 'Contact' }" class="btn btn-black"
            >Contact Us
          </router-link>
        </div>
      </div>
    </div>
  </section>

<!--  <section id="team">-->
<!--    <div class="container content">-->
<!--      <h1>Meet Our <strong>Executive Team</strong></h1>-->
<!--      <p>-->
<!--        Contrary to popular belief, Lorem Ipsum is not simply random text. It-->
<!--        has roots in a piece of classical Latin-->
<!--      </p>-->
<!--      <br />-->
<!--      <div class="d-md-flex mt-3 align-items-center">-->
<!--        <TeamMember-->
<!--          :img-path="'person1.jpg'"-->
<!--          :name="'Pawan Bagaria'"-->
<!--          :designation="'Founder'"-->
<!--          :desc="-->
<!--            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has\n' +-->
<!--            '                been the industry\'s standard dummy text ever since the 1500s'-->
<!--          "-->
<!--        />-->

<!--        <TeamMember-->
<!--          :img-path="'person2.jpg'"-->
<!--          :name="'Vishal Bagaria'"-->
<!--          :designation="'Director'"-->
<!--          :desc="-->
<!--            'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has\n' +-->
<!--            '                been the industry\'s standard dummy text ever since the 1500s'-->
<!--          "-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
</template>

<script>
import Industry from "../components/Industry";
// import TeamMember from "../components/TeamMember";

export default {
  name: "AboutUs",
  components: {
    // TeamMember,
    Industry },
  methods: {
    scrollTo() {
      const element = this.$refs["about-more"];
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 90vh;
  background-color: red;
  margin-top: -6.5rem;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.1)
    ),
    url("../assets/images/home/worker-executive.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;

  .content {
    color: white;
    text-align: left;
    margin-top: 4rem;

    h1 {
      font-size: 3rem;
      width: 70%;
      border-left: 8px solid $theme-color;
      padding-left: 1rem;
    }

    p {
      width: 50%;
      margin-top: 1.2rem;
      color: white;
    }

    @media (max-width: 768px) {
      margin-top: 8rem;

      h1 {
        font-size: 1.5rem;
        width: 100%;
      }
      p {
        font-size: 0.85rem;
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    height: 80vh;
  }
}

#about-more {
  margin: 6rem 0;
  position: relative;

  .container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    z-index: 1;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
}

.img-background-shape {
  position: absolute;
  height: 100%;
  width: 30%;
  background: $extra-light-blue-color;
  top: 0;
  left: 0;
  z-index: -1;
}

#expertise {
  color: white !important;
  background: url("../assets/images/about/about-background-2.jpg");
  background-size: cover;

  .content {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .row [class*="col-md"] {
    margin-top: 1rem;
  }
}

#why-choose-us {
  padding: 6rem 0;
  position: relative;

  .content {
    hr {
      width: 40%;
      display: block;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    h5 {
      font-weight: 300;

      svg {
        width: 2rem;
        color: $theme-color;
      }
    }
  }
}

#team {
  padding: 8rem 0;
  background: $section-background;
}
</style>
